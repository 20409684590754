var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._l((_vm.validations),function(validation,index){return _c('div',{key:validation._id},[_c('v-row',[_c('v-col',[(['validation', 'order'].indexOf(validation.type) !== -1)?_c('v-card',{class:_vm.activeIndex === index + 1 ? "teal lighten-5" : '',attrs:{"outlined":"","ripple":false},on:{"click":function($event){return _vm.selectBinder(validation.order.binder, index + 1)}}},[_c('v-card-actions',[_c('v-list-item',{staticClass:"grow"},[(
                  [
                    'to-order-buyer-pending',
                    'to-order-contractor-pending' ].indexOf(validation.status) !== -1
                )?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-list-item-avatar',_vm._g(_vm._b({staticClass:"mr-4",attrs:{"color":"blue"}},'v-list-item-avatar',attrs,false),on),[_c('v-icon',{attrs:{"color":"white","small":""}},[_vm._v("mdi-package-variant-closed")])],1)]}}],null,true)},[_c('span',[_vm._v("À commander")])]):_vm._e(),(['ordered'].indexOf(validation.status) !== -1)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-list-item-avatar',_vm._g(_vm._b({staticClass:"mr-4",attrs:{"color":"success"}},'v-list-item-avatar',attrs,false),on),[_c('v-icon',{attrs:{"color":"white","small":""}},[_vm._v("mdi-package-variant-closed-check")])],1)]}}],null,true)},[_c('span',[_vm._v("Commande passée le "+_vm._s(_vm.format(_vm.parseISO(validation.orderedAt), "dd.MM.yyyy")))])]):_vm._e(),(
                  ['cancel-pending', 'cancel'].indexOf(validation.status) !==
                  -1
                )?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-list-item-avatar',_vm._g(_vm._b({staticClass:"mr-4",attrs:{"color":"grey"}},'v-list-item-avatar',attrs,false),on),[_c('v-icon',{attrs:{"color":"white","small":""}},[_vm._v("mdi-timeline-remove-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Demande de validation annulée")])]):_vm._e(),(validation.status === 'rejected')?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-list-item-avatar',_vm._g(_vm._b({staticClass:"mr-4",attrs:{"color":"warning"}},'v-list-item-avatar',attrs,false),on),[_c('v-icon',{attrs:{"color":"white","small":""}},[_vm._v("mdi-undo")])],1)]}}],null,true)},[_c('span',[_vm._v("Validation refusée")])]):_vm._e(),(validation.status === 'po')?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-list-item-avatar',_vm._g(_vm._b({staticClass:"mr-4",attrs:{"color":"grey"}},'v-list-item-avatar',attrs,false),on),[_c('v-icon',{attrs:{"color":"white","small":""}},[_vm._v("mdi-file-document-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Création du bon de commande")])]):_vm._e(),(validation.status === 'pending')?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-list-item-avatar',_vm._g(_vm._b({staticClass:"mr-4",attrs:{"color":"blue"}},'v-list-item-avatar',attrs,false),on),[_c('v-icon',{attrs:{"color":"white","small":""}},[_vm._v("mdi-clock-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("En attente de validation")])]):_vm._e(),(validation.status === 'redirected')?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-list-item-avatar',_vm._g(_vm._b({staticClass:"mr-4",attrs:{"color":"warning"}},'v-list-item-avatar',attrs,false),on),[_c('v-icon',{attrs:{"color":"white","small":""}},[_vm._v("mdi-routes")])],1)]}}],null,true)},[_c('span',[_vm._v("Redirigée")])]):_vm._e(),(
                  [
                    'validated',
                    'to-order-buyer',
                    'to-order',
                    'ordered-supplier' ].indexOf(validation.status) > -1
                )?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-list-item-avatar',_vm._g(_vm._b({staticClass:"mr-4",attrs:{"color":"success"}},'v-list-item-avatar',attrs,false),on),[_c('v-icon',{attrs:{"color":"white","small":""}},[_vm._v("mdi-check")])],1)]}}],null,true)},[_c('span',[_vm._v("Validation acceptée")])]):_vm._e(),(validation.status === 'deleted')?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-list-item-avatar',_vm._g(_vm._b({staticClass:"mr-4",attrs:{"color":"error"}},'v-list-item-avatar',attrs,false),on),[_c('v-icon',{attrs:{"color":"white","small":""}},[_vm._v("mdi-close")])],1)]}}],null,true)},[_c('span',[_vm._v("BC supprimé")])]):_vm._e(),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(validation.fullname)),(
                      validation.validatorTla &&
                      validation.email === 'comptabilite@heig-vd.ch'
                    )?_c('span',[_vm._v(" - "+_vm._s(validation.validatorTla))]):_vm._e()]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.format(_vm.parseISO(validation.updatedAt), "dd.MM.yyyy")))]),(validation.comment)?_c('v-list-item-subtitle',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"comment"},'div',attrs,false),on),[_vm._v(" 1 commentaire ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(validation.comment))])])],1):_vm._e()],1),_c('v-row',{attrs:{"align":"center","justify":"end"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"icon":"","ripple":false,"plain":""}},[(_vm.activeIndex === index + 1)?_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",on:{"click":function($event){validation &&
                            validation.order &&
                            _vm.downloadFile(
                              validation.order.binder,
                              ("E-" + (validation.order.orderId) + "-" + (validation.order.creatorTla) + ".pdf")
                            )}}},'v-icon',attrs,false),on),[_vm._v(" mdi-cloud-download-outline ")]):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v("Télécharger BC")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
                            var attrs = ref.attrs;
return [(validation.changed)?_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1"},'v-icon',attrs,false),on),[_vm._v(" mdi-alert-box-outline ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Bon de commande modifié")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
                            var attrs = ref.attrs;
return [(
                        validation.type === 'validation' &&
                        [
                          'to-order-contractor-pending',
                          'to-order-buyer-pending',
                          'ordered',
                          'po',
                          'deleted' ].indexOf(validation.status) === -1
                      )?_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1"},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(("mdi-numeric-" + (validation.level) + "-box-outline"))+" ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Validation niveau "+_vm._s(validation.level))])])],1)],1)],1)],1):_vm._e(),(validation.notification)?_c('v-card',{staticClass:"mt-3",attrs:{"outlined":""}},[_c('v-card-text',[_c('div',[_c('div',{staticClass:"subtitle-2"},[_vm._v("E-mail fournisseur")]),_c('div',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.format( _vm.parseISO(validation.notification.date), "dd.MM.yyyy HH:mm" ))+" ")]),_c('div',[_vm._v(" À : "),(typeof validation.notification.to === 'string')?_c('a',{attrs:{"href":("mailto:" + (validation.notification.to))}},[_vm._v(_vm._s(validation.notification.to))]):_vm._l((validation.notification.to),function(email,i){return _c('span',{key:email},[_c('a',{key:email,attrs:{"href":("mailto:" + email)}},[_vm._v(_vm._s(email))]),(i < validation.notification.to.length - 1)?_c('span',[_vm._v(", ")]):_vm._e()])})],2),_c('div',[_vm._v(" Cc : "),_vm._l((validation.notification.cc),function(cc,index){return _c('span',{key:cc},[_c('a',{attrs:{"href":("mailto:" + cc)}},[_vm._v(_vm._s(cc))]),(validation.notification.cc.length - 1 > index)?_c('span',[_vm._v(", ")]):_vm._e()])})],2),_c('div',[_vm._v(" Objet : HEIG-VD - Commande E-"+_vm._s(validation.notification.orderId)+"-"+_vm._s(validation.notification.creatorTla)+" ")]),_c('v-divider',{staticClass:"my-5"}),_c('div',{domProps:{"innerHTML":_vm._s(validation.notification.html)}})],1)])],1):_vm._e(),(validation.type === 'notice')?_c('div',[_c('v-card',{attrs:{"outlined":"","ripple":false,"color":"grey lighten-4"}},[_c('v-card-actions',[_c('v-list-item',{staticClass:"grow"},[(validation.status === 'pending')?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-list-item-avatar',_vm._g(_vm._b({staticClass:"mr-4",attrs:{"color":"grey"}},'v-list-item-avatar',attrs,false),on),[_c('v-icon',{attrs:{"color":"white","small":""}},[_vm._v("mdi-information-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Demande de préavis envoyée")])]):_vm._e(),(validation.status === 'approved')?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-list-item-avatar',_vm._g(_vm._b({staticClass:"mr-4",attrs:{"color":"success"}},'v-list-item-avatar',attrs,false),on),[_c('v-icon',{attrs:{"color":"white","small":""}},[_vm._v("mdi-thumb-up")])],1)]}}],null,true)},[_c('span',[_vm._v("Préavis favorable")])]):_vm._e(),(validation.status === 'account-changed')?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-list-item-avatar',_vm._g(_vm._b({staticClass:"mr-4",attrs:{"color":"grey"}},'v-list-item-avatar',attrs,false),on),[_c('v-icon',{attrs:{"color":"white","small":""}},[_vm._v("mdi-alert-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Changement de compte")])]):_vm._e(),(validation.status === 'disapproved')?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-list-item-avatar',_vm._g(_vm._b({staticClass:"mr-4",attrs:{"color":"warning"}},'v-list-item-avatar',attrs,false),on),[_c('v-icon',{attrs:{"color":"white","small":""}},[_vm._v("mdi-thumb-down")])],1)]}}],null,true)},[_c('span',[_vm._v("Préavis défavorable")])]):_vm._e(),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(validation.notice.name)+" "),(
                        ['approved', 'disapproved'].indexOf(
                          validation.status
                        ) > -1 &&
                        validation.notice.validators.find(
                          function (v) { return v.email === validation.email; }
                        ).tla
                      )?_c('span',[_vm._v(" - "+_vm._s(validation.notice.validators.find( function (v) { return v.email === validation.email; } ).tla)+" ")]):_vm._e()]),(validation.comment)?_c('v-list-item-subtitle',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"comment"},'div',attrs,false),on),[_vm._v(" 1 commentaire ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(validation.comment))])])],1):_vm._e()],1)],1)],1)],1),(
              validation.status === 'pending' &&
              validation.notice.validators
                .map(function (v) { return v.email; })
                .indexOf(_vm.$keycloak.tokenParsed.email) > -1 &&
              _vm.validations.filter(
                function (v) { return v.type === 'notice' && v.status === 'pending'; }
              ).length &&
              _vm.validations
                .filter(
                  function (v) { return v.type === 'notice' &&
                    v.notice.number ===
                      _vm.validations.find(function (v) { return v.status === 'pending'; }).notice
                        .number; }
                )
                .map(function (v) { return v.status; })
                .indexOf(['approved', 'disapproved']) === -1
            )?_c('v-row',{staticClass:"mt-1"},[_c('v-col',[_c('v-btn',{attrs:{"block":"","outlined":"","color":"warning"},on:{"click":function($event){return _vm.openDialog('rejectNotice')}}},[_vm._v("Défavorable")])],1),_c('v-col',[_c('v-btn',{attrs:{"block":"","outlined":"","color":"success"},on:{"click":function($event){return _vm.openDialog('acceptNotice')}}},[_vm._v("Favorable")])],1)],1):_vm._e()],1):_vm._e(),(validation.notification)?_c('v-btn',{staticClass:"mt-3",attrs:{"block":"","outlined":""},on:{"click":function($event){return _vm.downloadArchive(
              validation._id,
              ("E-" + (validation.notification.orderId) + "-" + (validation.notification.creatorTla) + "-archive.pdf")
            )}}},[_vm._v("Télécharger une archive")]):_vm._e(),(
            !validation.notification &&
            _vm.validations.length - 1 === index &&
            ['ordered', 'approved', 'disapproved'].indexOf(
              validation.status
            ) > -1 &&
            validation.type !== 'notice'
          )?_c('v-btn',{staticClass:"mt-3",attrs:{"block":"","outlined":""},on:{"click":function($event){return _vm.downloadFile(
              validation.order.binder,
              ("E-" + (validation.order.orderId) + "-" + (validation.order.creatorTla) + "-archive.pdf")
            )}}},[_vm._v("Télécharger une archive")]):_vm._e(),(
            _vm.validations.length - 1 === index &&
            [
              'ordered',
              'approved',
              'disapproved',
              'to-order-buyer-pending',
              'ordered' ].indexOf(validation.status) > -1 &&
            validation.type !== 'notice' &&
            _vm.isAccountant
          )?_c('v-btn',{staticClass:"mt-2",attrs:{"block":"","outlined":"","color":"error"},on:{"click":function($event){return _vm.openDialog('accountantDeletion')}}},[_vm._v("Supprimer")]):_vm._e()],1)],1),(
        validation.status === 'cancel-pending' &&
        (validation.email === _vm.$keycloak.tokenParsed.email ||
          validation.validators
            .map(function (v) { return v.email; })
            .indexOf(_vm.$keycloak.tokenParsed.email) !== -1)
      )?_c('v-row',{staticClass:"mt-0"},[_c('v-col',[(!_vm.confirmationDialog)?_c('v-btn',{attrs:{"block":"","outlined":""},on:{"click":function($event){_vm.confirmationDialog = true}}},[_vm._v("Prise de connaissance de l'annulation")]):_c('v-btn',{attrs:{"loading":_vm.loading,"block":"","outlined":""},on:{"click":function($event){return _vm.acknowledgeCancelation(validation._id)}}},[_vm._v("Confirmer")])],1)],1):_vm._e()],1)}),(
      _vm.currentValidation &&
      _vm.currentValidation.email === 'comptabilite@heig-vd.ch' &&
      _vm.currentValidation.validators &&
      _vm.currentValidation.validators
        .map(function (v) { return v.email; })
        .indexOf(_vm.$keycloak.tokenParsed.email) > -1 &&
      !_vm.validationSubmitted &&
      this.dialogNames.map(function (dn) { return this$1[(dn + "Dialog")]; }).indexOf(true) === -1
    )?_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-btn',{attrs:{"block":"","outlined":"","to":((this.$route.path) + "/" + (_vm.currentValidation._id))}},[_vm._v("Éditer")])],1),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-btn',{attrs:{"block":"","outlined":"","color":"warning"},on:{"click":function($event){return _vm.openDialog('rejection')}}},[_vm._v("Refuser")])],1),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-btn',{attrs:{"block":"","outlined":"","color":"warning"},on:{"click":function($event){return _vm.openDialog('redirection')}}},[_vm._v("Rediriger")])],1),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-btn',{attrs:{"block":"","outlined":"","color":"error"},on:{"click":function($event){return _vm.openDialog('deletion')}}},[_vm._v("Supprimer")])],1),(_vm.currentValidation.order.buyer === 'Automatique')?_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-btn',{attrs:{"block":"","outlined":"","color":"success","disabled":!_vm.currentValidation.order.supplier.email},on:{"click":function($event){return _vm.openDialog('supplier')}}},[_vm._v("Commande fournisseur")])],1):_vm._e(),(_vm.currentValidation.order.buyer === 'Automatique')?_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-btn',{attrs:{"block":"","outlined":"","color":"success"},on:{"click":function($event){return _vm.openDialog('other')}}},[_vm._v("Commande autre")])],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-btn',{attrs:{"block":"","outlined":"","color":"success"},on:{"click":function($event){return _vm.openDialog('buyer')}}},[_vm._v("Commande requérant")])],1)],1):_vm._e(),(
      _vm.currentValidation &&
      _vm.currentValidation.email === _vm.$keycloak.tokenParsed.email &&
      !_vm.validationSubmitted &&
      [
        'deleted',
        'to-order-buyer-pending',
        'ordered',
        'to-order-contractor-pending' ].indexOf(_vm.currentValidation.status) === -1 &&
      this.dialogNames.map(function (dn) { return this$1[(dn + "Dialog")]; }).indexOf(true) === -1
    )?_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-btn',{attrs:{"block":"","outlined":"","color":"warning","disabled":_vm.currentValidation.level === 0},on:{"click":function($event){return _vm.openDialog('rejection')}}},[_vm._v("Refuser")])],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-btn',{attrs:{"block":"","outlined":"","color":"success"},on:{"click":function($event){return _vm.openDialog('validation')}}},[_vm._v("Valider")])],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-btn',{attrs:{"block":"","outlined":"","to":((this.$route.path) + "/" + (_vm.currentValidation._id))}},[_vm._v("Éditer")])],1),(
        _vm.currentValidation.email ===
        _vm.validations.find(function (v) { return v.status === 'po'; }).email
      )?_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-btn',{attrs:{"block":"","outlined":"","color":"error"},on:{"click":function($event){return _vm.openDialog('deletion')}}},[_vm._v("Supprimer")])],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-btn',{staticStyle:{"font-size":"9pt"},attrs:{"block":"","outlined":"","color":"success"},on:{"click":function($event){return _vm.openDialog('accounting')}}},[_vm._v("Valider et envoyer à la comptabilité")])],1)],1):_vm._e(),(_vm.computedUpdatable)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-btn',{attrs:{"block":"","outlined":""},on:{"click":function($event){return _vm.openDialog('validator')}}},[_vm._v("Annuler la demande de validation")])],1)],1):_vm._e(),(
      _vm.currentValidation &&
      _vm.currentValidation.email === _vm.$keycloak.tokenParsed.email &&
      ['to-order-buyer-pending', 'to-order-contractor-pending'].indexOf(
        _vm.currentValidation.status
      ) !== -1 &&
      _vm.validations.map(function (v) { return v.status; }).indexOf('deleted') === -1
    )?_c('v-row',[_c('v-col',[(!_vm.confirmationDialog)?_c('v-btn',{attrs:{"block":"","outlined":""},on:{"click":function($event){_vm.confirmationDialog = true}}},[_vm._v("Commande passée")]):_c('v-btn',{attrs:{"loading":_vm.loading,"block":"","outlined":""},on:{"click":function($event){return _vm.setOrdered(_vm.currentValidation._id, _vm.currentValidation.status)}}},[_vm._v("Confirmer")])],1)],1):_vm._e(),(
      _vm.currentValidation &&
      _vm.currentValidation.email === _vm.$keycloak.tokenParsed.email &&
      ['to-order-contractor-pending', 'to-order-buyer-pending'].indexOf(
        _vm.currentValidation.status
      ) !== -1 &&
      _vm.validations.map(function (v) { return v.status; }).indexOf('deleted') === -1
    )?_c('v-row',[_c('v-col',[_c('v-btn',{attrs:{"loading":_vm.loading,"block":"","outlined":"","color":"warning"},on:{"click":function($event){return _vm.openDialog('returnAccounting')}}},[_vm._v("Retour comptabilité")])],1)],1):_vm._e(),(
      _vm.currentValidation &&
      (_vm.currentValidation.email === _vm.$keycloak.tokenParsed.email ||
        (_vm.currentValidation.validators &&
          _vm.currentValidation.validators
            .map(function (v) { return v.email; })
            .indexOf(_vm.$keycloak.tokenParsed.email) > -1)) &&
      _vm.validationDialog &&
      !_vm.validationSubmitted
    )?_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_vm._v("Information importante")]),_c('v-card-text',[_c('div',[_vm._v("Information importante sur la validation.")]),_c('v-textarea',{staticClass:"mt-5",attrs:{"outlined":"","dense":"","label":"Commentaire"},model:{value:(_vm.validationComment),callback:function ($$v) {_vm.validationComment=$$v},expression:"validationComment"}}),_c('v-autocomplete',{attrs:{"items":_vm.managers,"search-input":_vm.searchManagers,"label":"Prochain validateur *","outlined":"","dense":"","no-data-text":"Tapez le nom d'une personne..."},on:{"update:searchInput":function($event){_vm.searchManagers=$event},"update:search-input":function($event){_vm.searchManagers=$event},"input":_vm.clearSearchManagers},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":"","dense":"","disabled":_vm.loading},on:{"click":_vm.closeDialog}},[_vm._v("Annuler")]),_c('v-btn',{attrs:{"outlined":"","dense":"","loading":_vm.loading,"color":"success","disabled":!_vm.selected},on:{"click":function($event){return _vm.updateValidation(
                _vm.selected.email,
                ((_vm.selected.firstname) + " " + (_vm.selected.lastname)),
                _vm.validationComment
              )}}},[_vm._v("Valider")])],1)],1)],1)],1):_vm._e(),(
      _vm.currentValidation &&
      (_vm.currentValidation.email === _vm.$keycloak.tokenParsed.email ||
        (_vm.currentValidation.validators &&
          _vm.currentValidation.validators
            .map(function (v) { return v.email; })
            .indexOf(_vm.$keycloak.tokenParsed.email) > -1)) &&
      _vm.redirectionDialog &&
      !_vm.validationSubmitted
    )?_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_vm._v("Information importante")]),_c('v-card-text',[_c('div',[_vm._v("Information importante sur la redirection.")]),_c('v-textarea',{staticClass:"mt-5",attrs:{"outlined":"","dense":"","label":"Commentaire"},model:{value:(_vm.validationComment),callback:function ($$v) {_vm.validationComment=$$v},expression:"validationComment"}}),_c('v-autocomplete',{attrs:{"items":_vm.managers,"search-input":_vm.searchManagers,"label":"Prochain validateur *","outlined":"","dense":"","no-data-text":"Tapez le nom d'une personne..."},on:{"update:searchInput":function($event){_vm.searchManagers=$event},"update:search-input":function($event){_vm.searchManagers=$event},"input":_vm.clearSearchManagers},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":"","dense":"","disabled":_vm.loading},on:{"click":_vm.closeDialog}},[_vm._v("Annuler")]),_c('v-btn',{attrs:{"outlined":"","dense":"","loading":_vm.loading,"color":"success","disabled":!_vm.selected},on:{"click":function($event){return _vm.redirectValidation(
                _vm.selected.email,
                ((_vm.selected.firstname) + " " + (_vm.selected.lastname)),
                _vm.validationComment
              )}}},[_vm._v("Rediriger")])],1)],1)],1)],1):_vm._e(),(
      _vm.currentValidation &&
      _vm.currentValidation.email === _vm.$keycloak.tokenParsed.email &&
      _vm.accountingDialog &&
      !_vm.validationSubmitted
    )?_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_vm._v("Information importante")]),_c('v-card-text',[_c('div',[_vm._v("Information importante sur l'envoi à la comptabilité.")]),_c('v-textarea',{staticClass:"mt-5",attrs:{"outlined":"","dense":"","label":"Commentaire"},model:{value:(_vm.validationComment),callback:function ($$v) {_vm.validationComment=$$v},expression:"validationComment"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":"","dense":"","disabled":_vm.loading},on:{"click":_vm.closeDialog}},[_vm._v("Annuler")]),_c('v-btn',{attrs:{"outlined":"","dense":"","loading":_vm.loading,"color":"success"},on:{"click":function($event){return _vm.submitToAccounting(_vm.validationComment)}}},[_vm._v("Envoyer")])],1)],1)],1)],1):_vm._e(),(
      _vm.currentValidation &&
      (_vm.currentValidation.email === _vm.$keycloak.tokenParsed.email ||
        (_vm.currentValidation.validators &&
          _vm.currentValidation.validators
            .map(function (v) { return v.email; })
            .indexOf(_vm.$keycloak.tokenParsed.email) > -1)) &&
      _vm.rejectionDialog &&
      !_vm.validationSubmitted
    )?_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_vm._v("Information importante")]),_c('v-card-text',[_c('div',[_vm._v("Information importante sur le refus.")]),_c('v-textarea',{staticClass:"mt-5",attrs:{"outlined":"","dense":"","label":"Commentaire *"},model:{value:(_vm.validationComment),callback:function ($$v) {_vm.validationComment=$$v},expression:"validationComment"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":"","dense":"","disabled":_vm.loading},on:{"click":_vm.closeDialog}},[_vm._v("Annuler")]),_c('v-btn',{attrs:{"outlined":"","dense":"","loading":_vm.loading,"color":"warning","disabled":!_vm.validationComment},on:{"click":function($event){return _vm.rejectValidation(_vm.validationComment)}}},[_vm._v("Refuser")])],1)],1)],1)],1):_vm._e(),(
      _vm.currentValidation &&
      (_vm.currentValidation.email === _vm.$keycloak.tokenParsed.email ||
        (_vm.currentValidation.validators &&
          _vm.currentValidation.validators
            .map(function (v) { return v.email; })
            .indexOf(_vm.$keycloak.tokenParsed.email) > -1)) &&
      _vm.returnAccountingDialog &&
      !_vm.validationSubmitted
    )?_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_vm._v("Information importante")]),_c('v-card-text',[_c('div',[_vm._v("Information importante sur le retour à la comptabilité.")]),_c('v-textarea',{staticClass:"mt-5",attrs:{"outlined":"","dense":"","label":"Commentaire *"},model:{value:(_vm.validationComment),callback:function ($$v) {_vm.validationComment=$$v},expression:"validationComment"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":"","dense":"","disabled":_vm.loading},on:{"click":_vm.closeDialog}},[_vm._v("Annuler")]),_c('v-btn',{attrs:{"outlined":"","dense":"","loading":_vm.loading,"color":"warning","disabled":!_vm.validationComment},on:{"click":function($event){return _vm.returnAccounting(_vm.validationComment)}}},[_vm._v("Retourner")])],1)],1)],1)],1):_vm._e(),(_vm.isAccountant && _vm.accountantDeletionDialog && !_vm.validationSubmitted)?_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_vm._v("Information importante")]),_c('v-card-text',[_vm._v(" Information importante sur la suppression. "),_c('v-textarea',{staticClass:"mt-5",attrs:{"outlined":"","dense":"","label":"Commentaire *"},model:{value:(_vm.validationComment),callback:function ($$v) {_vm.validationComment=$$v},expression:"validationComment"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":"","dense":"","disabled":_vm.loading},on:{"click":_vm.closeDialog}},[_vm._v("Annuler")]),_c('v-btn',{attrs:{"outlined":"","dense":"","loading":_vm.loading,"color":"error","disabled":!_vm.validationComment},on:{"click":function($event){return _vm.deletePoForAccountant(_vm.validationComment)}}},[_vm._v("Supprimer")])],1)],1)],1)],1):_vm._e(),(
      _vm.currentValidation &&
      (_vm.currentValidation.email === _vm.$keycloak.tokenParsed.email ||
        (_vm.currentValidation.validators &&
          _vm.currentValidation.validators
            .map(function (v) { return v.email; })
            .indexOf(_vm.$keycloak.tokenParsed.email) > -1)) &&
      _vm.deletionDialog
    )?_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_vm._v("Information importante")]),_c('v-card-text',[_vm._v(" Information importante sur la suppression. "),_c('v-textarea',{staticClass:"mt-5",attrs:{"outlined":"","dense":"","label":"Commentaire *"},model:{value:(_vm.validationComment),callback:function ($$v) {_vm.validationComment=$$v},expression:"validationComment"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":"","dense":"","disabled":_vm.loading},on:{"click":_vm.closeDialog}},[_vm._v("Annuler")]),_c('v-btn',{attrs:{"outlined":"","dense":"","loading":_vm.loading,"color":"error","disabled":!_vm.validationComment},on:{"click":function($event){return _vm.deletePo(_vm.validationComment)}}},[_vm._v("Supprimer")])],1)],1)],1)],1):_vm._e(),(
      _vm.currentValidation &&
      (_vm.currentValidation.email === _vm.$keycloak.tokenParsed.email ||
        (_vm.currentValidation.validators &&
          _vm.currentValidation.validators
            .map(function (v) { return v.email; })
            .indexOf(_vm.$keycloak.tokenParsed.email) > -1)) &&
      _vm.supplierDialog &&
      !_vm.validationSubmitted
    )?_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_vm._v("Information importante")]),_c('v-card-text',[_c('div',[_vm._v("Information importante sur la commande fournisseur.")]),_c('v-text-field',{staticClass:"mt-4",attrs:{"outlined":"","dense":"","label":"C/C finance"},model:{value:(_vm.ccFinance),callback:function ($$v) {_vm.ccFinance=$$v},expression:"ccFinance"}}),_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Pil"},model:{value:(_vm.pil),callback:function ($$v) {_vm.pil=$$v},expression:"pil"}}),_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Filière"},model:{value:(_vm.filiere),callback:function ($$v) {_vm.filiere=$$v},expression:"filiere"}}),_c('mailer',{ref:"mailer",attrs:{"validation":_vm.currentValidation}}),_c('v-autocomplete',{staticClass:"mt-4",attrs:{"items":_vm.managers,"search-input":_vm.searchManagers,"label":"Cc supplémentaires","outlined":"","dense":"","no-data-text":"Tapez le nom d'une personne...","multiple":""},on:{"update:searchInput":function($event){_vm.searchManagers=$event},"update:search-input":function($event){_vm.searchManagers=$event},"input":_vm.clearSearchManagers},model:{value:(_vm.additionalCcs),callback:function ($$v) {_vm.additionalCcs=$$v},expression:"additionalCcs"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":"","dense":"","disabled":_vm.loading},on:{"click":_vm.closeDialog}},[_vm._v("Annuler")]),_c('v-btn',{attrs:{"outlined":"","dense":"","loading":_vm.loading,"color":"success"},on:{"click":function($event){return _vm.orderSupplier(
                _vm.ccFinance,
                _vm.pil,
                _vm.filiere,
                _vm.$refs.mailer.content,
                _vm.additionalCcs
              )}}},[_vm._v("Commander")])],1)],1)],1)],1):_vm._e(),(
      _vm.currentValidation &&
      (_vm.currentValidation.email === _vm.$keycloak.tokenParsed.email ||
        (_vm.currentValidation.validators &&
          _vm.currentValidation.validators
            .map(function (v) { return v.email; })
            .indexOf(_vm.$keycloak.tokenParsed.email) > -1)) &&
      _vm.buyerDialog &&
      !_vm.validationSubmitted
    )?_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_vm._v("Information importante")]),_c('v-card-text',[_c('div',[_vm._v("Information importante sur la commande par le demandeur.")]),_c('v-textarea',{staticClass:"mt-5",attrs:{"outlined":"","dense":"","label":"Commentaire"},model:{value:(_vm.validationComment),callback:function ($$v) {_vm.validationComment=$$v},expression:"validationComment"}}),_c('v-text-field',{staticClass:"mt-4",attrs:{"outlined":"","dense":"","label":"C/C finance"},model:{value:(_vm.ccFinance),callback:function ($$v) {_vm.ccFinance=$$v},expression:"ccFinance"}}),_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Pil"},model:{value:(_vm.pil),callback:function ($$v) {_vm.pil=$$v},expression:"pil"}}),_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Filière"},model:{value:(_vm.filiere),callback:function ($$v) {_vm.filiere=$$v},expression:"filiere"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":"","dense":"","disabled":_vm.loading},on:{"click":_vm.closeDialog}},[_vm._v("Annuler")]),_c('v-btn',{attrs:{"outlined":"","dense":"","loading":_vm.loading,"color":"success"},on:{"click":function($event){return _vm.orderBuyer(_vm.ccFinance, _vm.pil, _vm.filiere, _vm.validationComment)}}},[_vm._v("Faire commander")])],1)],1)],1)],1):_vm._e(),(
      _vm.currentValidation &&
      (_vm.currentValidation.email === _vm.$keycloak.tokenParsed.email ||
        (_vm.currentValidation.validators &&
          _vm.currentValidation.validators
            .map(function (v) { return v.email; })
            .indexOf(_vm.$keycloak.tokenParsed.email) > -1)) &&
      _vm.otherDialog &&
      !_vm.validationSubmitted
    )?_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_vm._v("Information importante")]),_c('v-card-text',[_c('div',[_vm._v(" Information importante sur la commande par une autre personne. ")]),_c('v-autocomplete',{staticClass:"mt-5",attrs:{"items":_vm.buyers,"label":"Personne qui passera la commande *","outlined":"","dense":"","no-data-text":"Tapez le nom d'une personne...","item-text":function (v) { return ((v.firstname) + " " + (v.lastname)); },"item-value":function (v) { return v; }},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('v-textarea',{staticClass:"mt-5",attrs:{"outlined":"","dense":"","label":"Commentaire"},model:{value:(_vm.validationComment),callback:function ($$v) {_vm.validationComment=$$v},expression:"validationComment"}}),_c('v-text-field',{staticClass:"mt-4",attrs:{"outlined":"","dense":"","label":"C/C finance"},model:{value:(_vm.ccFinance),callback:function ($$v) {_vm.ccFinance=$$v},expression:"ccFinance"}}),_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Pil"},model:{value:(_vm.pil),callback:function ($$v) {_vm.pil=$$v},expression:"pil"}}),_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Filière"},model:{value:(_vm.filiere),callback:function ($$v) {_vm.filiere=$$v},expression:"filiere"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":"","dense":"","disabled":_vm.loading},on:{"click":_vm.closeDialog}},[_vm._v("Annuler")]),_c('v-btn',{attrs:{"outlined":"","dense":"","loading":_vm.loading,"color":"success","disabled":!_vm.selected},on:{"click":function($event){return _vm.orderOther(_vm.selected, _vm.ccFinance, _vm.pil, _vm.filiere, _vm.validationComment)}}},[_vm._v("Faire commander")])],1)],1)],1)],1):_vm._e(),(
      _vm.validations &&
      _vm.validations.find(
        function (v) { return v.type === 'notice' && v.status === 'pending'; }
      ) &&
      _vm.validations
        .find(function (v) { return v.type === 'notice' && v.status === 'pending'; })
        .notice.validators.map(function (v) { return v.email; })
        .indexOf(_vm.$keycloak.tokenParsed.email) > -1 &&
      _vm.validations.filter(function (v) { return v.type === 'notice' && v.status === 'pending'; })
        .length >
        _vm.validations.filter(
          function (v) { return v.type === 'notice' && v.status !== 'pending'; }
        ).length &&
      _vm.acceptNoticeDialog &&
      !_vm.validationSubmitted
    )?_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_vm._v("Information importante")]),_c('v-card-text',[_c('div',[_vm._v("Information importante sur le préavis positif.")]),_c('v-textarea',{staticClass:"mt-5",attrs:{"outlined":"","dense":"","label":"Commentaire"},model:{value:(_vm.validationComment),callback:function ($$v) {_vm.validationComment=$$v},expression:"validationComment"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":"","dense":"","disabled":_vm.loading},on:{"click":_vm.closeDialog}},[_vm._v("Annuler")]),_c('v-btn',{attrs:{"outlined":"","dense":"","loading":_vm.loading,"color":"success"},on:{"click":function($event){_vm.updateNoticeStatus(
                _vm.validations.find(
                  function (v) { return v.type === 'notice' && v.status === 'pending'; }
                ).order._id,
                _vm.validations.find(
                  function (v) { return v.type === 'notice' && v.status === 'pending'; }
                ).notice,
                'approved',
                _vm.validationComment
              )}}},[_vm._v("Valider")])],1)],1)],1)],1):_vm._e(),(_vm.validatorDialog && !_vm.validationUpdated)?_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_vm._v("Information importante")]),_c('v-card-text',[_c('div',[_vm._v("Information importante sur l'annulation de validation.")]),_c('v-autocomplete',{attrs:{"items":_vm.managers,"search-input":_vm.searchManagers,"label":"Prochain validateur *","outlined":"","dense":"","no-data-text":"Tapez le nom d'une personne..."},on:{"update:searchInput":function($event){_vm.searchManagers=$event},"update:search-input":function($event){_vm.searchManagers=$event},"input":_vm.clearSearchManagers},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":"","dense":"","disabled":_vm.loading},on:{"click":_vm.closeDialog}},[_vm._v("Annuler")]),_c('v-btn',{attrs:{"outlined":"","dense":"","loading":_vm.loading,"color":"success","disabled":!_vm.selected},on:{"click":function($event){return _vm.cancelValidation(
                _vm.selected.email,
                ((_vm.selected.firstname) + " " + (_vm.selected.lastname))
              )}}},[_vm._v("Valider")])],1)],1)],1)],1):_vm._e(),(
      _vm.validations &&
      _vm.validations.find(
        function (v) { return v.type === 'notice' && v.status === 'pending'; }
      ) &&
      _vm.validations
        .find(function (v) { return v.type === 'notice' && v.status === 'pending'; })
        .notice.validators.map(function (v) { return v.email; })
        .indexOf(_vm.$keycloak.tokenParsed.email) > -1 &&
      _vm.validations.filter(function (v) { return v.type === 'notice' && v.status === 'pending'; })
        .length >
        _vm.validations.filter(
          function (v) { return v.type === 'notice' && v.status !== 'pending'; }
        ).length &&
      _vm.rejectNoticeDialog &&
      !_vm.validationSubmitted
    )?_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_vm._v("Information importante")]),_c('v-card-text',[_c('div',[_vm._v("Information importante sur le préavis négatif.")]),_c('v-textarea',{staticClass:"mt-5",attrs:{"outlined":"","dense":"","label":"Commentaire"},model:{value:(_vm.validationComment),callback:function ($$v) {_vm.validationComment=$$v},expression:"validationComment"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":"","dense":"","disabled":_vm.loading},on:{"click":_vm.closeDialog}},[_vm._v("Annuler")]),_c('v-btn',{attrs:{"outlined":"","dense":"","loading":_vm.loading,"color":"warning"},on:{"click":function($event){_vm.updateNoticeStatus(
                _vm.validations.find(
                  function (v) { return v.type === 'notice' && v.status === 'pending'; }
                ).order._id,
                _vm.validations.find(
                  function (v) { return v.type === 'notice' && v.status === 'pending'; }
                ).notice,
                'disapproved',
                _vm.validationComment
              )}}},[_vm._v("Valider")])],1)],1)],1)],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }